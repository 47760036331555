.main {
  position: relative;
  height: 100%;
  background: var(--background-gradient-blue);
  overflow: hidden;
  margin: auto;
}

.container {
  max-width: 1500px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

.RSPBprogressBar {
  margin: 40px 0 60px 0;
}