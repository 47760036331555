@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700');

html {
  font-size: 16px;
  background: var(--background-gradient-blue);
  margin: 0;
  overscroll-behavior: none;
} /*16px*/

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
  overflow-y: scroll;
  overscroll-behavior: none;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

.wallet-icon {
  margin-top: 0.3rem;
}

.protect-inputbox {
  margin-left: auto !important;
  margin-right: 7px !important;
  max-width: 77% !important;
}



@media only screen and (max-width: 499px) {
  .body {
    overscroll-behavior: auto !important;
  }

  .html {
    background: var(--components-secondary-blue) !important;
    overscroll-behavior: auto !important;
  }

  .walletbuttonmodal-buttons-flexbox {
    flex-direction: column;
  }

  .logout-button {
    margin-bottom: 15px;
  }

  .wallet-icon {
    display: none;
  }

  .protect-inputbox {
    max-width: 100% !important;
    text-align: left !important;
  }
}

@media only screen and (max-width: 334px) {
  .header-text {
    font-size: var(--h2-font-size) !important;
  }
}


:root {
  /* global colors */
  --background-gradient-blue: linear-gradient(89.56deg, #000320 0.65%, #00195A 99.88%);
  --background-blue: #163081;
  --table-background-blue: #16308170;
  --possible-table-color: #ffffff10;
  --components-primary-blue: #2288FF;
  --components-secondary-blue: #001B6E;
  --text-primary-white: #FAFAFA;
  --text-secondary-gray: #D2D2D2;
  --text-error: #DC143C;
  --divider-blue: #192C64;
  --modal-blue: #011857;
  --button-opaque-white: #ffffff15;
  --banner-gradient-blue: linear-gradient(180deg, #001964 0%, #04278E 100%);
  --overlay-modal-black: var(--chakra-colors-blackAlpha-800);

  /* card radius */
  --card-radius: 0.75rem;
  --modal-radius: 15px;

  /* left && right page padding */
  --page-padding: 2rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* button left margin */
  --button-lm: 0.5rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.3;

  --description-font-size: 1.5rem;

  /* h1 attributes */
  --h1-font-size: 2.25rem;
  --h1-mt: 0;

  /* h2 attributes */
  --h2-font-size: 1.25rem;

  /* h3 attributes */
  --h3-font-size: 1.15rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* chevron size */
  --chevron-size: 0.4rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.75rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* horizontal grid spacing */
  --column-gap: 2.5rem;

  /* section bottom margin */
  --section-bm: 4.5rem;
}